.navigation {
  &.desktop {
    width: 100%;

    .navigation-container {
      width: 100%;
      position: fixed;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 10;
      padding: 36px 94px 36px 88px;
      background-color: transparent;

      &.colour-change {
        background-color: #ffffff;
      }

      .logo {
        width: 202px;
        height: 48px;
      }

      .menu {
        display: flex;
        justify-content: center;
        align-items: center;
        div {
          cursor: pointer;

          &.login-button {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            margin: 0 32px 0;
            padding: 0 72px 0 27px;
            border-radius: 28px;
            background-image: linear-gradient(110deg, #ff9180 6%, #d91d22 94%);

            &:hover {
              background-image: linear-gradient(110deg, #ffa597 6%, #ff5459 94%);
            }

            span {
              padding-right: 15px;
              font-size: 16px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 40px;
              letter-spacing: 1.5px;
              color: #fff;
            }

            img {
              position: absolute;
              right: 8%;
              transform: rotate(20deg);
              //width: 50px;
              height: 62px;
              animation: shake 2s;
              animation-iteration-count: infinite;
            }

            @keyframes shake {
              0% { transform: translate(1px, 1px) rotate(20deg); }
              10% { transform: translate(-1px, -2px) rotate(-21deg); }
              20% { transform: translate(-3px, 0px) rotate(21deg); }
              30% { transform: translate(3px, 2px) rotate(20deg); }
              40% { transform: translate(1px, -1px) rotate(21deg); }
              50% { transform: translate(-1px, 2px) rotate(-21deg); }
              60% { transform: translate(-3px, 1px) rotate(20deg); }
              70% { transform: translate(3px, 1px) rotate(-21deg); }
              80% { transform: translate(-1px, -1px) rotate(21deg); }
              90% { transform: translate(1px, 2px) rotate(20deg); }
              100% { transform: translate(1px, -2px) rotate(-21deg); }
            }
          }

          &.language-select, &.login-select {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 20px;
              height: 20px;
              margin-left: 8px;
              margin-right: 8px;
            }

            .react-select__control {
              display: flex;
              justify-content: center;

              .react-select__value-container {
                justify-content: center;
                align-items: center;

                .react-select__placeholder {
                  color: #000018;
                }

                .react-select__single-value {
                  font-size: 16px;
                  font-weight: normal;
                  font-stretch: normal;
                  line-height: 1.5;
                  letter-spacing: 1.5px;
                  color: #000018;
                  margin-left: 0;
                  margin-right: 0;
                  top: 54%;
                }
              }

              .react-select__indicators {
                .react-select__indicator-separator {
                  display: none;
                }
                .react-select__indicator {

                }
              }
            }

            .login-dropdown {
              margin-right: 20px;
              .react-select__control {
                width: 120px;
              }
            }

            .language-dropdown {
              .react-select__control {
                width: 70px;

                .react-select__value-container {
                  padding: 2px 0;
                }

                .react-select__indicators {
                  .react-select__indicator {
                    padding: 8px 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.mobile {
    display: none;

    .navigation-container {
      position: fixed;
      width: 100vw;
      z-index: 10;
      height: 80px;
      padding: 22px 16px;
      display: flex;
      justify-content: space-between;
      background-color: transparent;

      &.colour-change {
        background-color: #ffffff;
      }

      .container-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .logo {
          a {
            text-decoration: none;
          }
          img {
            content: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/mobile/logo.png');
          }
        }
      }

      .container-right {
        display: flex;
        align-items: center;
        .login {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .open-account {
            cursor: pointer;
            height: 36px;
            padding: 8px 12px;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            background-color: red;
            color: rgba(255, 255, 255, 0.95);
          }

          .language-select, .login-select {
            .react-select__control {
              .react-select__value-container {
                text-align: center;

                .react-select__placeholder {
                  color: #000018;
                }
              }

              .react-select__indicators {
                display: none;
              }
            }
          }

          .login-select {
            .react-select__control {
              width: 110px;
            }
            .react-select__menu {
              white-space: nowrap;
              font-size: 14px;
              color: #00001c !important;
            }
          }

          .language-select {
            .react-select__control {
              width: 70px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .navigation {
    &.desktop {
      display: none;
    }

    &.mobile {
      display: block;
    }
  }
}
