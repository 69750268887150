.mask {
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;

    img {
        width: 100%;
        height: auto;
    }
}

@media only screen and (max-width: 1280px) {
    .mask img {
        height: 100vh;
    }
}
