.banner {
  margin-bottom: 160px;

  &.en {
    .banner-container .banner-text .title {
      font-size: 75px;
      font-weight: 500;
    }
  }

  &.vn {
    .banner-container .banner-text .title {
      font-size: 60px;
      font-weight: 500;
    }
  }

  &.th {
    .banner-container .banner-text .title {
      font-size: 60px;
      font-weight: 300;
    }
  }

  &.sc,&.tc {
    .banner-container .banner-text .title {
      font-size: 94px;
      font-weight: 300;
    }
  }

  .banner-container {
    position: relative;
    width: 100%;
    height: 950px;
    background: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/banner.jpg') no-repeat center;

    .banner-text {
      position: absolute;
      top: 25%;
      left: 13%;

      img {
        width: 564px;
        height: 40px;
        margin-bottom: 19px;
      }

      .title {
        margin-bottom: 32px;
        //font-size: 5.2vw;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.47px;
        color: #000018;
      }

      .desc {
        margin-bottom: 57px;
        //font-size: 2.6vw;
        font-size: 44px;
        font-weight: 600;
        color: #d91d1d;
        //text-shadow: -3px -3px 0 #fff, 3px -3px 0 #fff, -3px 3px 0 #fff, 3px 3px 0 #fff, 3px 4px 3px #d91d1d;
        text-shadow : -2px -2px #fff, -2px -1.5px #fff, -2px -1px #fff, -2px -0.5px #fff, -2px 0 #fff, -2px 0.5px #fff, -2px 1px #fff, -2px 1.5px #fff, -2px 2px #fff, -1.5px 2px #fff, -1px 2px #fff, -0.5px 2px #fff, 0 2px #fff, 0.5px 2px #fff, 1px 2px #fff, 1.5px 2px #fff, 2px 2px #fff, 2px 1.5px #fff, 2px 1px #fff, 2px 0.5px #fff, 2px 0 #fff, 2px -0.5px #fff, 2px -1px #fff, 2px -1.5px #fff, 2px -2px #fff, 1.5px -2px #fff, 1px -2px #fff, 0.5px -2px #fff, 0 -2px #fff, -0.5px -2px #fff, -1px -2px #fff, -1.5px -2px #fff, 3px 4px 3px #d91d1d, -3px 4px 3px #d91d1d;
      }

      .button.desktop {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 30%;
        height: 56px;
        //font-size: 1.2vw;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.5px;
        color: #fff;
        border-radius: 28px;
        box-shadow: 0 2px 4px 0 rgba(230, 0, 0, 0.5);
        background-image: linear-gradient(114deg, #ff9180 5%, #d91d22 96%);

        &:hover {
          box-shadow: 0 2px 4px 0 rgba(230, 0, 0, 0.5);
          background-image: linear-gradient(114deg, #ffa597 5%, #ff5459 96%);
        }
      }
    }

    .button.mobile {
      display: none;
    }
  }
}

@media only screen and (max-width: 768px) {
  .banner {
    margin-bottom: 56px;
    text-align: center;

    &.en, &.sc, &.tc {
      .banner-container .banner-text .title {
        font-size: 32px !important;
      }
    }

    &.th, &.vn {
      .banner-container .banner-text .title {
        font-size: 26px !important;
      }
    }

    .banner-container {
      height: 700px;
      background: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/mobile/banner-mobile.png') no-repeat center;
      display: flex;
      justify-content: center;

      .banner-text {
        left: unset;

        img {
          width: 225px;
          height: 17px;
          margin-bottom: 5px;
        }

        .title {
          margin-bottom: 4px;
        }

        .desc {
          font-size: 19px;
          //text-shadow: -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff, 2px 2px 0 #fff;
        }

        .button.desktop {
          display: none;
        }
      }

      .button.mobile {
        display: flex;
        justify-content: center;
        position: absolute;
        margin: 0 auto 33px;
        left: 0;
        right: 0;
        bottom: 0;
        width: 200px;
        height: 40px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.35px;
        text-align: center;
        color: #fff;
        padding: 8px 42px 8px 43px;
        border-radius: 24px;
        background-image: linear-gradient(113deg, #ff9180 5%, #d91d22 95%);
        white-space: nowrap;
      }
    }
  }
}
