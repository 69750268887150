$cdn: "https://cdn.dootech.io/prime-website2/images/";

// for footer use only
html {
  font-size: 53.5715%;
}

.alignCenter {text-align: center;}

.footer {
  z-index: 5;
  background-color: rgba(239, 241, 243, 0.5);
  position: relative;
  .container {
    max-width: 1381px;
    width: auto;
    margin: auto;
  }
}

.footer-content {
  z-index: 10;
  position: relative;

  .top-footer {
    .container {
      padding: 80px 0 0;
      width: 83.3%;
      margin: 0 auto;

      .grid {
        text-transform: capitalize;

        .remove_arrow {
          margin-right: 200px;
          .social {
            height: 100%;
            padding: 1.1rem 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            .text {
              div {
                p {
                  white-space: nowrap;
                  text-transform: none;
                  //font-size: 1.4rem;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.57;
                  letter-spacing: 1.5px;
                  color: #7f8286;
                  &.title {
                    font-size: 16px !important;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.38;
                    letter-spacing: 1.71px;
                    color: #000018;
                  }
                }
              }
            }
            .brand {
              display: inline-block;
              margin-bottom: 28px;
              img {
                //max-width: 13.8rem;
              }
            }
            .pull_right {
              a {
                margin-right: 8px;
                display: inline-block;
                padding-top: 8px;
                &:last-child {
                  margin-right: 0;
                }
                img {
                  max-width: 2.4rem;
                  display: inline-block;
                  vertical-align: text-bottom;
                }
              }
              .qr_wechat {
                position: relative;
                z-index: 1;
                &:hover,
                :focus {
                  &:before {
                    content: "";
                    width: 110px;
                    height: 114px;
                    border-radius: 10px;
                    border: 4px solid #fff;
                    position: absolute;
                    right: 0;
                    top: 40px;
                    background: url("#{$cdn}qr-wechat.png") no-repeat left top;
                  }
                }
              }
            }
          }
        }
        h5 {
          //font-size: 1.6rem;
          font-size: 16px;
          font-weight: bold;
          //margin-bottom: 1rem;
          margin-bottom: 16px;
          color: #8a8a9c;
        }
        p {
          font-size: 14px;
          margin-bottom: 16px;
          font-weight: 400;
        }
        span {
          //font-size: 1.2rem;
          font-size: 12px;
          font-weight: bold;
          //margin-top: 1.6rem;
          margin-bottom: 7px;
          display: block;
          color: #000;
          padding-left:13px;
          position:relative;
          margin-top: 32px;
          &:before {
            content:'';
            width:8px;
            height:2px;
            background:#d91d22;
            position:absolute;
            left:0;
            top:50%;
            transform: rotate(90deg);
          }

          &:first-child {
            margin-top: 0;
          }
        }
        p {
          color: #000018;
        }
      }

      span.Collapsible__trigger {
        font-size: 16px;
        font-weight: bold;
        padding-top: 16px;
        padding-bottom: 16px;
        border-top: solid 1px #e4e4e4;
        color: #000018;
        line-height: 1.56;
        letter-spacing: .16px;
        display: block;
        &:after {
          content: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/mobile/expand.png');
          height: 20px;
          width: 20px;
          //border: solid #000018;
          //border-width: 0 2px 2px 0;
          //-ms-transform: rotate(-45deg);
          //-webkit-transform: rotate(-45deg);
          //transform: rotate(-45deg);
          //height: 1.2rem;
          //width: 1.2rem;
          position: absolute;
          top: 20px;
          right: 9px;
        }
        &.is-open:after {
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
  }

  .middle-footer {
    background-color: #eff1f3;
    .container {
      padding: 0;
      width: 83.3%;
      margin: 0 auto;

      .compliance {
        .complianceTitle {
          padding-top: 41px;
          padding-bottom: 15px;
          margin-bottom: 0;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.57;
          letter-spacing: normal;
          color: #000018;
        }

        p {
          padding-bottom: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: 1.31px;
          color: #16a0ff;
        }
      }
      .compliance-container {
        span {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: 1.31px;
          color: #000018;
        }

        div {
          padding-top: 16px;
          padding-bottom: 48px;
          p {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: 1.31px;
            color: #000018;
          }
        }
      }

      .compliance_desc {
        &.desktop {
          display: block;
          padding-bottom: 89px;

          .react-tabs {
            .react-tabs__tab-list {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              border-top: solid 1px #e4e4e4;
              border-bottom: solid 1px #e4e4e4;
              margin-bottom: 24px;

              .react-tabs__tab {
                cursor: pointer;
                margin-right: 100px;
                padding-top: 16px;
                padding-bottom: 17px;
                list-style-type: none;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: 1.5px;
                color: #000019;

                &.react-tabs__tab--selected {
                  //border-bottom: 2px solid #ec3f33;
                  position: relative;
                  z-index : 1;
                  font-weight: bold !important;

                  &:before {
                    content : "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom  : 0;
                    margin-left: auto;
                    margin-right: auto;
                    height  : 1px;
                    width   : 48px;
                    border-bottom: 2px solid #ec3f33;
                  }
                }
              }
            }

            .react-tabs__tab-panel {
              div p {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #000018;
              }

              .bulletin {
                h3 {
                  color: #000018;
                  font-weight: bold;
                }
                a {
                  color: #000018;
                }
              }
            }
          }
        }
        &.mobile {
          display: none;
        }
        padding-bottom:0;
        margin-bottom:0;
      }

      .complianceWrapper {
        li {
          border-top: 1px solid rgba(255, 255, 255, 0.15);
          position: relative;
        }
        .content {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.2s ease-out;
        }
        span.Collapsible__trigger {
          font-size: 14px;
          font-weight: bold;
          padding-top: 16px;
          padding-bottom: 16px;
          border-top: solid 1px #e4e4e4;
          color: #000018;
          line-height: 1.56;
          letter-spacing: .16px;
          display: block;
          &:after {
            content: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/mobile/expand.png');
            height: 20px;
            width: 20px;
            //content: '';
            //border: solid #000018;
            //border-width: 0 2px 2px 0;
            //-ms-transform: rotate(-45deg);
            //-webkit-transform: rotate(-45deg);
            //transform: rotate(-45deg);
            //height: 1.2rem;
            //width: 1.2rem;
            position: absolute;
            top: 20px;
            right: -4px;
          }
          &.is-open:after {
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
        p {
          color: #000018;
        }
        .bulletin {
          h3 {
            color: #000018;
            font-weight: bold;
          }
          a {
            color: #000018;
          }
        }
      }
    }

    .footer_menu {
      .container {
        padding: 0;
        margin-bottom: 26px;
        text-align: center;
        .doo_select_input {
          margin-right: -2.3rem !important;
        }
      }
      a {
        font-size: 1.4rem;
        color: #595959;
        display: inline-block;
        position: relative;
        text-transform: capitalize;
        text-decoration: none;
        padding: 0 24px 0 0;
        margin-right: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right:0;
          margin-right:0;
          &:after {
            display: none;
          }
        }
        &:after {
          content: "";
          width: 1px;
          height: 8px;
          background: #595959;
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -3px;
        }
        &.remove_border {
          &:after {
            display:none;
          }
        }
      }
      .pull_right {
        top: -5px;
        .doo_select_lang {
          .doo_select_input {
            min-width: auto;
          }
          .doo_select_value:before {
            top: -8px;
          }
        }
      }
    }

    .copyright {
      background: #131329;
      padding: 18px 0;
      color: rgba(255, 255, 255, 0.95);
      .container {
        font-size: 1.4rem;
        line-height: 1.8;
        color: rgba(255, 255, 255, 0.95);
        text-align: center;
        padding: 0 50px;
      }
    }
  }

  h5 {
    font-size: 16px;
    line-height: 1.56;
    letter-spacing: 0.16px;
    margin-bottom: 24px;
  }
  p {
    color: rgba(255, 255, 255, 0.37);
    margin-bottom: 2rem;
  }

  .container {
    font-size: 1.4rem;
    > h3 {
      font-size: 1.6rem;
    }
  }

  hr {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    border: 1px solid rgba(255, 255, 255, 1);
  }
  .bulletin {
    margin-top:40px;
    .card {
      width: 50%;
      display: inline-block;
      vertical-align: top;
      padding-bottom:38px;
      padding-right:90px;
    }
    h3, a {
      color:rgba(255, 255, 255, 0.95);
    }
    h3 {
      margin-bottom:6px;
      font-weight: 400;
    }
    p {
      color:rgba(255, 255, 255, 0.37);
      margin-bottom:7px;
      &:first-letter {
        text-transform: capitalize;
      }
    }
    a {
      display:block;
      text-transform: lowercase;
      margin-bottom:6px;
    }
  }
}

.grid {
  display: grid;
  padding-bottom: 6.6rem;
  grid-gap: 2.5rem;
  &.column3 {
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
  }
  &.column4 {
    grid-template-columns: repeat(4, 1fr);
  }
  &.column6 {
    grid-template-columns: repeat(6, 1fr);
  }
  &.column7 {
    grid-template-columns: repeat(7, 1fr);
  }
}

.disclaimer-subtitle {
  margin-bottom: 0 !important;
}

.jurisdiction, .jurisdiction_eu, .jurisdiction_aus, .jurisdiction_uk {
  visibility: visible !important;
}

@media screen and (max-width: 1299px) {
  .footer-content .footer_menu a {
    &:last-child {
      float: initial;
    }
  }
}

@media screen and (min-width: 768px){
  .hide_mdlg {
    display:none !important;
  }
}

@media screen and (max-width: 767px) {
  .hide_sm {
    display:none !important;
  }
  .hide_mdlg {
    margin-bottom: 40px;
  }
  .footer-content {
    .social {
      padding-top: 32px;
      text-align: left;
      .brand {
        img {
          max-width:166px;
        }
      }
      .text {
        margin-top: 40px;
        div {
          p {
            text-transform: none;
            font-size: 1.4rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.57;
            letter-spacing: 1.5px;
            color: #7f8286;

            &.title {
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.38;
              letter-spacing: 1.71px;
              color: #000018;
            }
          }
        }
      }
      .sm-container {
        margin-top: 48px;
        margin-bottom: 40px;
        a {
          margin-right: 24px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .top-footer {
      > .container {
        padding: 0 15px !important;
        width: 100%;

        .grid {
          grid-template-columns: initial;
          padding-bottom:0;
          .column {
            &:first-child {
              padding-bottom:0;
              .social {
                padding:0;
                a {
                  margin-bottom: 16px;
                }
              }
            }
          }
          h5 {
            font-size:16px;
          }
        }
      }

      .column {
        position:relative;
        &.remove_arrow {
          &:before, &:after {
            display:none;
          }
        }
        &:last-child {
          a {
            padding-right: 11px;
            img {
              max-width: 2.4rem;
              width:100%;
            }
            &:last-child {
              padding-right:0;
            }
          }

          span.Collapsible__trigger {
            &:after {
              content: none
            }
          }
        }

        &.social_media {
          //margin-top: 40px;
          //padding-bottom: 22px;
          display: none;
        }
      }
      .content {
        span {
          font-size: 1.2rem;
          margin-top: 1.6rem;
          margin-bottom: 7px;
          display: block;
          color: grey;
        }
        p {
          font-size: 1.6rem;
          color: #000018;
          margin-bottom: 5px;
          font-weight: 400;
        }
      }
    }
    .middle-footer {
      .container {
        padding: 0 24px 0;
        width: 100%;

        .compliance_desc {
          &.desktop {
            display: none !important;
          }
          &.mobile {
            display: block !important;

            li {
              list-style-type: none;

              &:last-child {
                span.Collapsible__trigger {
                  &:after {
                    content: none
                  }
                }
              }
            }
          }
        }
      }

      .footer_menu {
        background: #eff1f3;
        padding-top: 41px;
        padding-bottom: 48px;
        .container {
          text-align: left;
          padding: 0 16px 0 24px;

          a {
            font-size: 14px;
            line-height: 25px;
            margin-bottom: 24px;

            &:last-child {
              padding: 0 12px 0 0;
              margin-bottom: 0;
            }
          }
        }
      }

      .copyright {
        padding: 24px 0;
        .container {
          font-size: 14px;
        }
      }
    }
  }
  .footer .complianceWrapper .Collapsible .Collapsible__trigger {
    font-style: normal;
  }
}

@media screen and (max-width: 500px) {
  .footer-content .bulletin {
    margin-top: 0;
    .card {
      width: 100%;
      padding-right: 0;
    }
  }
}
