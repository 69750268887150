.terms {
  padding-bottom: 183px;

  .terms-container {
    margin: 0 auto;
    width: 72.8vw;
    text-align: left;

    .title {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      margin-bottom: 40px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 1.5px;
      color: #000018;

      div {
        //font-size: 2.8vw;
        font-size: 48px;
        line-height: normal;
        white-space: nowrap;
      }

      span {
        margin-left: 26px;
        //font-size: 1.1vw !important;
        font-size: 16px !important;
        line-height: 1.5;
      }
    }

    .line {
      width: 100%;
      height: 10px;
      box-shadow: 0 2px 4px 0 rgba(255, 255, 255, 0.5), inset 0 1px 3px 0 rgba(214, 214, 214, 0.5);
      background-color: #fff;
    }

    .list {
      margin-top: 33px;
      //font-size: 1.1vw;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.5;
      letter-spacing: 1.5px;
      color: #000018;

      ul {
        list-style: none;

        li {
          margin-top: 16px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .terms {
    .terms-container {
      width: 100vw;
      padding: 40px 20px 13px;

      .title {
        flex-direction: column;
        align-items: center;
        text-align: center;

        div {
          font-size: 24px;
          letter-spacing: 0.75px;
        }

        span {
          display: block;
          margin-top: 8px;
          margin-left: 0;
          font-size: 14px !important;
        }
      }

      .list {
        font-size: 14px;
      }
    }
  }
}
