.modal-container {
  z-index: 30;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .6);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .modal {
    max-width: 416px;
    width: 100%;
    height: auto;
    background-color: #fff;
    border-radius: 10px;
    padding: 48px 20px 40px;
    position:relative;

    .close {
      cursor: pointer;
      z-index: 30;
      width: 14px;
      height: 14px;
      color: #ccc;
      box-shadow: none;
      border: 0;
      position: absolute;
      top: 17px;
      right: 17px;
      background:none;
    }
    .modal-header {
      position: relative;
      .modal-header-title {
        text-align: center;
      }
    }
    .modal-content {
      padding: 10px 0;
    }
    .modal-footer {
      display: flex;
      justify-content: center;
      padding: 10px 0;
    }

    .modal-submit,
    .modal-close {
      background-color: transparent;
      font-weight: 600;
      &:hover {
        color: rgba(54, 67, 72, 0.8);
      }
    }
    .login-form input {
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
}
