.crm-register {
  flex-direction: column;

  .modal {
    padding: 48px 20px 80px;

    .modal-header {
      height: 100%;

      .popup-wrapper {
        height: 100%;

        .title {
          margin-left: 24px;
          font-size: 32px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #252525;
        }

        .content {
          margin-left: 24px;
          margin-bottom: 16px;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #252525;

          span {
            cursor: pointer;
            font-size: 14px;
            font-weight: 600;
            color: #e72e2e;
          }
        }

        div {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
