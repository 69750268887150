.redeem-success {
  &.vn {
    #packet-amount {  
      top:21%;
      div {
        &.title {
          font-size:22.2px;
        }
      }
    }
  }
  .modal {
    position: relative;
    max-width: 500px;
    min-height: 643px;
    background-color: transparent;
    padding: 0;
    border-radius: 25.1px;
  }

  .close {
    top: 0 !important;
    right: 0 !important;
    height: 43px !important;
    width: 43px !important;
    border-radius: 50% !important;
    background-color: #c5c5c5 !important;
    font-size: 40px;
    color: #fff !important;
  }

  #svga-loader {
    width: 100%;
  }

  #packet-amount {
    display: none;
    position: absolute;
    top: 23%;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;

    div {
      &.title {
        font-size: 27.2px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.85px;
        color: #b48035;
      }

      &.number {
        div {
          display: inline;
          width: 205px;
          font-size: 88px;
          font-weight: bold;
          background-image: linear-gradient(126deg, #b48035 2%, #ebc388 50%, #b48035 92%);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        span {
          font-size: 21px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          font-variation-settings: "opsz" 20.96;
          line-height: normal;
          letter-spacing: 0.66px;
          color: #b48035;
        }
      }
    }
  }

  .text-wrapper {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 300px;
    text-align: center;

    .info {
      margin-bottom: 16px;
      font-size: 28px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.88px;
      color: #b48035;
    }

    .button-get {
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      //font-size: 1.6vw;
      font-size: 37.7px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.18px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      border-radius: 45.1px;
      box-shadow: 0 2px 4px 0 rgba(230, 0, 0, 0.5);
      background-image: linear-gradient(114deg, #ff9180 5%, #e91f25 96%);
      margin-bottom: 17px;

      &:hover {
        background-image: linear-gradient(114deg, #ffa597 5%, #ff5459 96%);
      }
    }

    .deposit {
      margin-top: 11px;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.5px;
      text-align: center;
      color: #fff;
      text-decoration: underline;
    }
  }

  .disclaimer {
    position: absolute;
    bottom: 8%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1.5px;
    color: #bf7e1e;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .redeem-success {
    &.vn {
      #packet-amount {
        div.title {
          font-size: 8.2px;
        }
      }
      
    }
    .close {
      top: 10% !important;
      right: 5% !important;
      height: 30px !important;
      width: 30px !important;
      font-size: 20px;
    }

    #packet-amount {
      top: 86px;
      div.title {
        font-size:17.2px;
      }
      div.number {
        div {
          font-size:49px;
        }
      }
    }

    .text-wrapper {
      text-align: center;
      bottom:4%;
      .info {
        font-size:12px;
      }
      .button-get {
        font-size: 14px;
        bottom: 37%;
        display: block;
        max-width: 150px;
        width: 100%;
        margin: 0 auto 15px;
        text-align: center;
        border-radius: 50px;
        color: #fff;
        padding: 10px 0;
      }
    }
    .modal {
      max-width:290px;
      min-height: initial;
    }
    a {
      color: #fff;
      font-size:11px;
    }
    .disclaimer {
      font-size:8px;
      text-align: center;
    }
  }
}

@media only screen and (max-width: 375px) {
  .redeem-success {
    .text-wrapper {
      min-width: 200px;
    }
  }
}
