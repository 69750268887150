.comments {
  margin-bottom: 160px;
  background: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/comment-background.png') no-repeat center;
  background-size: cover;

  .comments-container {
    margin: 0 auto;
    width: 83.3vw;
    text-align: center;

    .title {
      margin-bottom: 24px;
      //font-size: 2.8vw;
      font-size: 48px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.5px;
      color: #000018;
    }

    .desc {
      margin-bottom: 53px;
      //font-size: 1.1vw;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 1.5px;
      text-align: center;
      color: #000018;
    }

    .info-box {
      margin-bottom: 32px;
      width: 100%;
      height: 36px;
      overflow-x: hidden;
      background-image: linear-gradient(to left, #fff 5%, #ffe3e3 95%, #fff 100%);
      //font-size: 1.1vw;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 36px;
      letter-spacing: 1.5px;
      text-align: center;
      color: #000018;

      div {
        padding-right: 50px;
      }
    }

    .comments-box {
      position: relative;
      width: 100%;
      height: auto;
      padding: 9px 14px 0;
      border-radius: 11px;
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.15);
      background-color: rgba(235, 240, 245, 0.36);

      &.mobile {
        display: none;
      }

      img {
        &.comments-box-img {
          width: 100%;
          position: relative;
          background-color: #ffffff;
          object-fit: contain;
        }

        &.map-img {
          position: absolute;
          top: 3%;
          left: 0;
          right: 0;
          width: 100%;
        }
      }

      .comments-box-container {
        .amount {
          position: absolute;
          top: 28px;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            //font-size: 1.7vw;
            font-size: 28px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 1.5px;
            color: #000018;
          }

          .amount-box {
            height: 53px;
            margin-left: 11px;
            padding: 11px 21px 10px 13px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            border-radius: 5.3px;
            -webkit-backdrop-filter: blur(2.6px);
            backdrop-filter: blur(2.6px);
            background-color: rgba(255, 189, 189, 0.87);

            img {
              width: 32px;
              height: 32px;
              margin-right: 13px;
              background-color: transparent;
            }

            span {
              position: relative;
              padding: 0 10px 0 0;
              margin-right: 10px;
              //font-size: 1.2vw;
              font-size: 23.8px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: center;
              color: #252525;

              &:after {
                content: '';
                margin-top: -7px;
                width: 1px;
                height: 14px;
                background-color: rgba(102, 34, 34, 0.24);
                position: absolute;
                right: 0;
                top: 50%;
              }

              &:last-child {
                padding: 0;
                margin-right: 0;
                &:after {
                  display: none;
                }
              }
            }
          }
        }

        .comments-display {
          position: absolute;
          top: 15%;
          left: 2%;
          right: 3%;
          margin: 0 8px;

          #individual-comment {
            margin-bottom: 10px !important;
            margin-right: 50px !important;
            height: 55px;
            position: relative;
            white-space: nowrap;

            img {
              position: absolute;
              top: 0;
              width: 75px;
              height: 6px;
            }

            span {
              &.comment-content {
                padding: 10px;
                //font-size: 1.1vw;
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 40px;
                letter-spacing: 1.5px;
                color: #000018;
                background-color: #f4f4f4;
              }

              &.comment-author {
                display: block;
                //font-size: 0.8vw;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 2;
                letter-spacing: 1.13px;
                text-align: left;
                color: #111;
              }
            }

            //&.normal {
            //  span {
            //    &.comment-content {
            //      color: #000018;
            //      background-color: #f4f4f4;
            //    }
            //
            //    &.comment-author {
            //      color: #111;
            //    }
            //  }
            //}

            &.new {
              span {
                &.comment-content {
                  color: #d91d22;
                  background-color: #ffebeb;
                }

                &.comment-author {
                  color: #d91d22;
                }
              }
            }
          }

          .loading {
            display: flex;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            transition: all 1s linear;
            background: transparent;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .predefined-comments {
        width: 87%;
        margin: 24px auto 0;
        text-align: left;

        div {
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          margin-bottom: 24px;
          margin-right: 24px;
          height: 40px;
          padding: 0 16px 0;
          background-color: #fff;
          //font-size: 1.1vw;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 1.5px;
          color: #000018;
        }
      }

      .comments-send {
        width: 87%;
        padding-top: 24px;
        padding-bottom: 34px;
        margin: 0 auto;
        text-align: left;

        .comment-input {
          height: 88px;
          margin: 0 auto 16px;
          display: flex;
          align-items: center;

          input {
            width: 70%;
            height: 100%;
            border: none;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            background-color: #fff;
            padding: 0 20px;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 1.5px;
          }

          .button {
            width: 30%;
            height: 100%;
            background-color: #fff;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              //font-size: 1.1vw;
              margin-right: 21px;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: 1.5px;
              text-align: center;
              color: #cacaca;
            }

            button {
              cursor: pointer;
              min-width: 220px;
              margin-right: 24px;
              //width: 60%;
              height: 56px;
              border-radius: 28px;
              box-shadow: 0 2px 4px 0 rgba(230, 0, 0, 0.5);
              background-image: linear-gradient(114deg, #ff9180 5%, #d91d22 96%);
              //font-size: 1.4vw;
              font-size: 20px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 56px;
              letter-spacing: 1.5px;
              color: #fff;
              text-align: center;
              border: none;
              outline: none;

              &:hover {
                background-image: linear-gradient(114deg, #ffa597 5%, #ff5459 96%);
              }

              &:disabled {
                cursor: not-allowed;
                pointer-events: all !important;
              }
            }
          }
        }

        .comment-terms {
          width: 87%;
          margin: 0 auto 0;
          //font-size: 1.1vw;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 1.5px;
          color: #000018;
          text-align: left;
          text-decoration: underline;
          cursor: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/competition-trade/tooltip-cursor.png'), auto;
        }
      }
    }
  }

  .tooltip {
    overflow: visible;
    left: 5% !important;
  }
}

@media only screen and (max-width: 768px) {
  .comments {
    margin-bottom: 40px;
    background: unset;

    .comments-container {
      width: 100%;

      .title {
        margin-bottom: 16px;
        font-size: 24px;
        letter-spacing: 0.75px;
      }

      .desc {
        margin: 0 20px 16px;
        font-size: 14px;
        line-height: 1.71;
        letter-spacing: 1.31px;
      }

      .info-box {
        margin-bottom: 24px;
        font-size: 16px;

        div {
          white-space: nowrap;
          padding-right: 15px;
          margin-right: 0;
        }
      }

      .comments-box {
        &.mobile {
          display: block;
          padding: 40px 14px 0;

          .amount {
            position: unset;
            flex-direction: column;

            span {
              margin-bottom: 16px;
              font-size: 24px;
              font-weight: normal;
              line-height: normal;
              letter-spacing: 0.75px;
            }

            .amount-box {
              margin-bottom: 16px;
              height: 44px;

              img {
                width: 24px;
                height: 24px;
              }

              span {
                margin-bottom: 0;
                font-size: 20px;
              }
            }
          }

          .container {
            padding: 7px 20px 12px;
            height: 460px;
            position: relative;

            img {
              height: 100%;
              object-fit: cover;
            }

            .comments-display {
              width: 80%;
              height: 85%;
              margin: 0 auto;
              overflow: hidden;
              top: 35px;

              .comments-ticker {
                height: auto !important;
              }

              #individual-comment {
                height: auto;
                text-align: left;
                white-space: normal;
                margin-bottom: 20px !important;
                margin-right: 0 !important;

                span {
                  &.comment-content {
                    font-size: 14px;
                  }

                  &.comment-author {
                    font-size: 12px;
                  }
                }
              }
            }
          }

          .predefined-comments {
            margin: 8px auto 0;

            div {
              margin-bottom: 8px;
              margin-right: 12px;
              font-size: 12px;
            }
          }

          .comments-send {
            padding: 16px 0 40px;

            .comment-input {
              width: 100%;
              height: 64px;

              input {
                width: 80%;
              }

              .button {
                justify-content: center;
                align-items: flex-end;
                span {
                  width: 20%;
                  font-size: 12px;
                }
              }
            }

            .submit {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;

              .comment-terms {
                font-size: 14px;
              }
              button {
                cursor: pointer;
                width: 100px;
                height: 32px;
                padding: 7px 26px 6px;
                border-radius: 28px;
                background-image: linear-gradient(125deg, #ff9180 4%, #d91d22 101%);
                font-size: 14px;
                letter-spacing: normal;
                color: #fff;
                border: none;
                outline: none;

                &:disabled {
                  cursor: not-allowed;
                  pointer-events: all !important;
                }
              }
            }
          }
        }

        &.desktop {
          display: none;
        }
      }
    }
  }
}
