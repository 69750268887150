.rewards {
  margin-bottom: 160px;

  &.sc, &.tc {
    .card-container.mobile .button-get {
      width: 50%;
      font-size: 16px;
    }
  }

  &.en, &.th {
    .card-container.mobile .button-get {
      width: 50%;
      font-size: 16px;
    }
  }

  &.vn {
    .card-container.mobile .button-get {
      width: 100%;
      font-size: 13px;
    }
  }

  .rewards-container {
    margin: 0 auto;
    width: 72.8vw;
    text-align: center;

    .title {
      margin-bottom: 23px;
      //font-size: 2.8vw;
      font-size: 48px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.5px;
      color: #000018;
    }

    .desc {
      padding-bottom: 23px;
      //font-size: 1.1vw;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 1.5px;
      text-align: center;
      color: #000018;

      span {
        font-weight: bold;
      }
    }

    .user-records {
      .record-card {
        padding: 37px 0 54px;
        box-shadow: 0 2px 8px 0 rgba(186, 186, 186, 0.5);
        background-color: #f8f9fa;
        text-align: left;

        &:last-child {
          margin: 26px 0 26px;
        }

        .record-card-wrapper {
          width: 90%;
          margin: 0 auto;

          .header {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            padding-bottom: 23px;
            border-bottom: solid 1px #d91d22;

            .record-title {
              font-size: 1.8vw;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 0.88px;
              color: #000018;
            }

            .record-desc {
              font-size: 0.8vw;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 1.5px;
              color: #808080;
            }
          }

          .record-container {
            margin-top: 32px;
            color: #000018;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;

            div {
              display: inline-block;
              margin-right: 60px;
              font-size: 1vw;

              letter-spacing: 0.56px;

              span {
                display: block;
                margin-top: 16px;
                font-size: 1.8vw;
                letter-spacing: 0.88px;
              }
            }
          }
        }
      }
    }

    .card-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .button-get {
        min-width: 220px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        //font-size: 1.2vw;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.5px;
        text-align: center;
        color: #fff;
        cursor: pointer;
        border-radius: 28px;
        white-space: nowrap;
        padding: 0 15px;

        &.redeem {
          box-shadow: 0 2px 4px 0 rgba(230, 0, 0, 0.5);
          background-image: linear-gradient(114deg, #ff9180 5%, #e91f25 96%);

          &:hover {
            background-image: linear-gradient(114deg, #ffa597 5%, #ff5459 96%);
          }
        }

        &.deposit {
          box-shadow: 0 2px 4px 0 rgba(181, 120, 0, 0.5);
          background-image: linear-gradient(114deg, #ffd580 5%, #e98e1f 96%);

          &:hover {
            background-image: linear-gradient(114deg, #ffe7b5 5%, #e98e1f 96%);
          }
        }
      }

      &.desktop {
        .card {
          position: relative;
          width: 32%;
          padding: 48px 0 48px;
          border-radius: 14px;
          box-shadow: 0 2px 12px 0 rgba(161, 161, 161, 0.21);
          border: solid 1px #f0f0f1;
          background-image: linear-gradient(161deg, #f9f9f9 -5%, #fff 172%);

          .title {
            cursor: pointer;
            width: 70%;
            margin: 0 auto 40px;
            //font-size: 1.8vw;
            font-size: 28px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.86;
            letter-spacing: 2.63px;
            color: #000;
            -webkit-animation: font-style 2s 1;
            -moz-animation: font-style 2s 1;
            -o-animation: font-style 2s 1;
            animation: font-style 2s 1;
          }

          @-webkit-keyframes font-style  {
            0% { transform: scale(0.8); }
            100% {transform: scale(1); }
          }

          @-moz-keyframes font-style  {
            0% { transform: scale(0.8); }
            100% {transform: scale(1); }
          }

          @-o-keyframes font-style  {
            0% { transform: scale(0.8); }
            100% {transform: scale(1); }
          }

          @keyframes font-style  {
            0% { transform: scale(0.8); }
            100% {transform: scale(1); }
          }

          .slider-container {
            position: relative;

            .slider {
              position: absolute;
              bottom: 0;
              max-height: 0;
              width: 100%;
              overflow-y: hidden;
              -webkit-transition: max-height 0.8s ease-in-out;
              -moz-transition: max-height 0.8s ease-in-out;
              -o-transition: max-height 0.8s ease-in-out;
              transition: max-height 0.8s ease-in-out;

              span {
                position: absolute;
                top: 8%;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                color: saddlebrown;
                font-weight: bold;
              }
            }
          }

          img {
            position: relative;
            width: 60%;
            height: auto;
          }

          .desc-container {
            position: absolute;
            background-image: linear-gradient(139deg, #fff 3%, #f1f3f5 142%);
            width: 100%;

            .desc {
              margin: -16px 16px auto;
              z-index: 5;
              background-image: linear-gradient(139deg, #fff 3%, #f1f3f5 142%);

              div {
                padding-top: 48px;
                margin: 0;
              }

              &:before{
                content: "";
                width: 60%;
                height: 2px;
                background: white;
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                -webkit-box-shadow: 0 -3px 3px 0 rgba(161, 161, 161, 0.21);
                -moz-box-shadow: 0 -3px 3px 0 rgba(161, 161, 161, 0.21);
                box-shadow: 0 -3px 0 rgba(161, 161, 161, 0.21);
              }
            }

            .button-wrapper {
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translate(-50%, -50%);
              margin: 0 auto 28px;

              .availability {
                margin: 0 0 11px;
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 2;
                letter-spacing: 1.5px;
                text-align: center;
                color: #bf711d;
              }
            }
          }
        }
      }

      &.mobile {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .rewards {
    margin-bottom: 40px;

    &.sc, &.tc {
      .card-container.mobile .button-get {
        width: 50%;
        font-size: 16px;
      }
    }

    &.en, &.th {
      .card-container.mobile .button-get {
        width: 60%;
        font-size: 13px;
      }
    }

    &.vn {
      .card-container.mobile .button-get {
        width: 100%;
        font-size: 13px;
      }
    }

    .rewards-container {
      width: 100vw;
      padding: 0 20px 0;

      .title {
        margin-bottom: 16px;
        font-size: 24px;
        letter-spacing: 0.75px;
      }

      .desc {
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 1.71;
        letter-spacing: 1.31px;
      }

      .user-records {
        .record-card {
          padding: 20px 0 20px;

          .record-card-wrapper {
            .header {
              flex-direction: column;
              align-items: flex-start;
              padding-bottom: 18px;

              .record-title {
                font-size: 16px;
              }

              .record-desc {
                margin-top: 8px;
                font-size: 12px;
              }
            }

            .record-container {
              margin-top: 16px;
              div {
                display: block;
                font-size: 12px;
                margin-right: 0;
                margin-bottom: 16px;

                span {
                  margin-top: 8px;
                  font-size: 20px;
                }
              }
            }
          }
        }
      }

      .card-container {
        &.desktop {
          display: none;
        }

        &.mobile {
          display: flex;
          flex-direction: column;

          .button-get {
            min-width: unset;
          }

          .card {
            width: 100%;
            margin-bottom: 16px;
            padding: 20px 21px 20px 16px;
            box-shadow: 0 0 3px 0 rgba(161, 161, 161, 0.21);
            border: solid 0.5px #f0f0f1;
            background-image: linear-gradient(132deg, #f9f9f9 5%, #fff 135%);
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            .card-wrapper {
              display: flex;
              .image {
                width: 40%;

                img {
                  width: 100%;
                }
              }

              .text {
                width: 60%;
                margin-left: 20px;

                .title {
                  margin-bottom: 10px;
                  font-size: 20px;
                  font-weight: bold;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.2;
                  letter-spacing: 1.88px;
                  color: #000;
                  text-align: left;
                }

                .desc {
                  margin-bottom: 0;
                  font-size: 12px;
                  font-weight: bold;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.5;
                  letter-spacing: normal;
                  color: #000018;
                  text-align: left;
                }

                .availability {
                  margin: 8px 0;
                  font-size: 12px;
                  font-weight: bold;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 2;
                  letter-spacing: 1.5px;
                  text-align: left;
                  color: #bf711d;
                }
              }
            }

            .button-get {
              //width: 50%;
              height: 40px;
              position: unset;
              //font-size: 8.7px;
              transform: unset;
              margin: 16px auto;
            }
          }
        }
      }
    }
  }
}
