/* Global */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font: normal 16px -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Segoe UI Symbol,Microsoft Yahei;
    width: 100%;
    height: 100%;
    color: #000;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
}

body, html { overflow-x:hidden; }
