.info {
  .popup-wrapper {
    div {
      text-align: center;
      padding: 0 !important;

      p {
        text-align: left;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .info {
    &.modal-container .modal {
      height: auto;
    }
  }
}
