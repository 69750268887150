.introduction {
  margin-bottom: 128px;

  .introduction-container {
    margin: 0 auto;
    width: 72.8vw;

    .timeline {
      width: 100%;
      height: 1px;
      list-style: none;
      text-align: justify;
      margin: 120px auto;
      display: flex;
      justify-content: space-around;
      align-items: center;
      background: linear-gradient(to left, #ff1f1f, #fff 100%);

      li {
        display: inline-block;
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 1.2;
        position: relative;
        border-radius: 50%;
        background-color: #d91d22;

        &:nth-child(1) {
          opacity: 0.2;
        }

        &:nth-child(2) {
          opacity: 0.2;
        }

        &:nth-child(3) {
          opacity: 0.3;
        }

        &:nth-child(4) {
          opacity: 0.4;
        }

        &:nth-child(5) {
          opacity: 0.5;
        }

        &:nth-child(6) {
          opacity: 0.6;
        }

        &:nth-child(7) {
          opacity: 0.7;
        }

        &:before {
          display: inline-block;
          content: attr(data-year);
          //font-size: 1.8vw;
          font-size: 24px;
          font-weight: 600;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: 0.75px;
          text-align: center;
          color: #000019;
          position: absolute;
          top: 0;
          left: 50%;
          margin-top: -21px;
          transform: translate(-50%, -100%);
        }
      }
    }

    div {
      width: 82%;
      margin: 0 auto;
      //font-size: 1.1vw;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 1.5px;
      text-align: center;
      color: #000018;
    }
  }
}

@media only screen and (max-width: 768px) {
  .introduction {
    margin-bottom: 56px;

    .introduction-container {
      margin: 0 17px 0;
      width: unset;

      .timeline {
        margin: 76px auto 18px;
        li {
          height: 5px;
          width: 5px;

          &:before {
            font-size: 12px;
            font-weight: 590.8;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: 0.37px;
            text-align: center;
            color: #000018;
            margin-top: -6px;
          }
        }
      }
      div {
        width: 100%;
        font-size: 16px;
      }
    }
  }
}
