.guide {
  position: relative;
  margin-bottom: 160px;
  background: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/info.jpg') no-repeat center;
  background-size: cover;

  .guide-container {
    margin: 0 auto;
    padding: 98px 0 19px;
    width: 72.8vw;

    .text {
      .title {
        //font-size: 2.8vw;
        font-size: 40px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.5px;
        color: #fff;
      }

      .desc {
        display: flex;
        align-items: center;
        //font-size: 1.1vw;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 1.5px;
        color: #fff;

        img {
          width: 109px;
          height: auto;
          margin-bottom: 16px;
        }
      }

      .event {
        display: flex;
        align-items: baseline;
        margin-top: 9px;

        .number {
          display: flex;
          justify-content: center;
          align-items: center;
          //font-size: 1.6vw;
          font-size: 26.6px;
          font-weight: bold;
          font-stretch: normal;
          font-style: oblique;
          line-height: normal;
          letter-spacing: 2.49px;
          color: #d91d22;
          border-radius: 50%;
          width: 38px;
          height: 38px;
          background: #fff;
          text-align: center;
        }

        .list {
          margin-top: 8px;
          margin-left: 17px;
          .title {
            display: flex;
            align-items: center;
            //font-size: 1.8vw;
            font-size: 28px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: 2.63px;
            color: #fff;

            .tag {
              display: inline;
              margin-left: 17px;
              padding: 0 17px 0;
              height: 24px;
              //font-size: 1.0vw;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.71;
              letter-spacing: 1.31px;
              text-align: center;
              border-top-left-radius: 8px;
              border-bottom-right-radius: 8px;
              white-space: nowrap;

              &.ended {
                color: #a1a1a1;
                background-color: #ffffff;
              }

              &.soon {
                color: #d06f00;
                background-color: #feedce;
              }

              &.ongoing {
                color: #fff;
                background-color: #ff565b;
              }
            }
          }

          .desc {
            margin-top: 16px;
            //font-size: 1.4vw;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: rgba(255, 255, 255, 0.67);
          }
        }
      }

      .disclaimer {
        position: absolute;
        bottom: 0;
        margin-bottom: 19px;
        //font-size: 1vw;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.31px;
        color: #fff;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .guide {
    //height: 560px !important;
    margin-bottom: 0;

    .guide-container {
      width: 100vw;
      padding: 40px 20px 13px;

      .text {
        text-align: center;

        .title {
          font-size: 24px;
          font-weight: normal;
          letter-spacing: 0.75px;
        }

        .desc {
          align-items: flex-start;
          margin-top: 16px;
          flex-direction: column;
          font-size: 14px;
          font-weight: normal;
          line-height: 1.71;
          letter-spacing: 1.31px;

          img {
            margin: 17px auto 16px;
          }
        }

        .event {
          margin-top: 12px;
          align-items: normal;

          .number {
            font-size: 17.7px;
            width: 26px;
            height: 26px;
            letter-spacing: normal;
          }

          .list {
            margin-top: 0;
            text-align: left;
            .title {
              font-size: 16px;

              .tag {
                font-size: 12px;
              }
            }
            .desc {
              margin-top: 4px;
              font-size: 12px;
            }
          }
        }

        .disclaimer {
          text-align: left;
          margin-bottom: 13px;
          font-size: 12px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .guide .guide-container .text .event {
    .number {
      width: 6% !important;
    }

    .list {
      width: 94% !important;
    }
  }
}

@media only screen and (max-width: 414px) {
  .guide .guide-container .text .event {
    .number {
      width: 8% !important;
    }

    .list {
      width: 92% !important;
    }
  }
}

@media only screen and (max-width: 320px) {
  .guide .guide-container .text .event {
    .number {
      width: 10% !important;
    }

    .list {
      width: 90% !important;
    }
  }
}
