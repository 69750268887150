.redeem {
  &.vn {
    .button-get {
      white-space: break-spaces;
      max-width: 320px;
      width:100%;
      font-size: 20px;
      bottom: 23%;
    }
  }

  &.sc, &.tc {
    .modal span {
      font-size: 33.5px;
      top: 8%;
    }
  }

  &.en, &.th, &.vn {
    .modal span {
      font-size: 20px;
      top: 10%;
    }
  }

  .modal {
    position: relative;
    max-width: 500px;
    min-height: 643px;
    background: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/rewards-packet.png') no-repeat center;
    background-size: contain;
    padding: 0;
    border-radius: 25.1px;

    span {
      position: absolute;
      top: 10%;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      color: saddlebrown;
      font-weight: bold;
      text-align: center;
    }
  }

  .close {
    top: 0 !important;
    right: 0 !important;
    height: 43px !important;
    width: 43px !important;
    border-radius: 50% !important;
    background-color: #c5c5c5 !important;
    font-size: 40px;
    color: #fff !important;
  }

  .button-get {
    white-space: nowrap;
    padding: 19px 15px;
    min-height: 90px;
    min-width: 272px;
    position: absolute;
    bottom: 24%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    //font-size: 1.6vw;
    font-size: 37.7px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.18px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    border-radius: 45.1px;
    box-shadow: 0 2px 4px 0 rgba(230, 0, 0, 0.5);
    background-image: linear-gradient(114deg, #ff9180 5%, #e91f25 96%);

    &:hover {
      background-image: linear-gradient(114deg, #ffa597 5%, #ff5459 96%);
    }
  }
}

@media only screen and (max-width: 767px) {
  .redeem {
    &.vn {
      .button-get {
        bottom: 25%;
        width:65%;
      }
    }
    .modal {
      background-size: contain;

      span {
        top: 23%;
      }
    }

    .close {
      top: 10% !important;
      right: 5% !important;
      height: 30px !important;
      width: 30px !important;
      font-size: 20px;
    }

    .button-get {
      font-size: 14px;
      bottom: 33%;
      padding: 19px 15px;
      min-height: 40px;
      min-width: unset;
    }
  }
}

@media only screen and (max-width: 540px) {
  .redeem .modal span {
    top: 11%;
  }
}

@media only screen and (max-width: 425px) {
  .redeem {
    &.sc, &.tc {
      .modal span {
        font-size: 22px;
        top: 18.5%;
      }
    }

    &.en, &.th, &.vn {
      .modal span {
        font-size: 16px;
        top: 19%;
      }
    }
  }
}

@media only screen and (max-width: 414px) {
  .redeem .modal span {
    top: 20%;
  }
}

@media only screen and (max-width: 375px) {
  .redeem {
    &.sc, &.tc {
      .modal span {
        font-size: 22px !important;
        top: 22%;
      }
    }

    &.en, &.th, &.vn {
      .modal span {
        font-size: 14px !important;
        top: 23%;
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  .redeem .modal span {
    top: 24%;
  }
}


@media only screen and (max-width: 320px) {
  .redeem {
    &.sc, &.tc {
      .modal span {
        top: 26% !important;
      }
    }

    &.en, &.th, &.vn {
      .modal span {
        top: 27% !important;
      }
    }
  }
}

@media only screen and (max-width: 280px) {
  .redeem .modal span {
    top: 30%;
  }
}
