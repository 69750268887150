.redirection {
  article {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0 1.4rem;
    img {
      margin: 0 21px;
      width: 80px;
    }
    p {
      color: #393939;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
    }
  }
  footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.6rem 0 1rem;
    border-top: 1px dashed #d2d2d2;
    p {
      font-size: 17px;
      color: #7a7a7a;
      text-align: center;
    }
    a {
      text-decoration: none;
      font-size: 17px;
      color: #ffffff;
      line-height: 42px;
      height: 42px;
      margin-top: 19px;
      padding: 0 45px;
      background-color: #e51c1c;
    }
  }
}

@media only screen and (max-width: 767px) {
  .redirection {
    article {
      p {
        font-size: 18px;
      }
    }
    footer {
      p, a {
        font-size: 15px;
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .redirection {
    article {
      p {
        font-size: 14px;
      }
    }
    footer {
      p, a {
        font-size: 12px;
      }
    }
  }
}
